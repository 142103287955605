@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Sora', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;

    -moz-appearance: textfield !important;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .embla__viewport {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
    flex-direction: row;
    height: auto;
    /* margin-left: calc(var(--slide-spacing) * -1); */
  }
  .embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    position: relative;
  }

  p,
  li {
    font-size: 14px;
    line-height: 1.7;
  }

  .embla__dot,
  .embla__button {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .embla__dots {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .embla__dot {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .embla__dot:after {
    background: transparent;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    content: '';
    border: 1px solid white;
  }
  .embla__dot--selected:after {
    background: white;
  }
  .embla__button {
    z-index: 1;
    color: yellow;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid rgb(240 177 13 / var(--tw-bg-opacity));
    border-radius: 50%;
    transition: 0.5s;
  }
  .embla__button svg path {
    transition: 0.5s;
    fill: rgb(240 177 13 / var(--tw-bg-opacity));
  }
  .embla__button--prev {
    right: 10rem;
    bottom: 3rem;
  }
  .embla__button--next {
    right: 6rem;
    bottom: 3rem;
  }
  .embla__button:disabled {
    opacity: 0.3;
  }
  .embla__button__svg {
    width: 35%;
    height: 35%;
  }
  .embla__button:hover {
    background: rgb(240 177 13 / var(--tw-bg-opacity));
  }

  .embla__button:hover svg path {
    fill: white;
  }
  .emblaPriceList {
    padding-right: 24px;
  }
  .emblaPriceList .embla__button--next {
    right: -8px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .emblaPriceList .embla__slide:nth-child(odd) {
    flex: 0 0 100%;
  }

  .emblaPriceList .embla__slide:nth-child(even) {
    flex: 0 0 100%;
  }

  header nav svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  @media all and (min-width: 1280px) {
    .emblaPriceList .embla__slide:nth-child(odd) {
      flex: 0 0 60%;
      padding-left: 24px;
    }

    .emblaPriceList .embla__slide:nth-child(even) {
      flex: 0 0 40%;
      padding-left: 24px;
    }
    .emblaPriceList .embla__button--next {
      right: -48px;
    }
    .priceListImgOverlay {
      left: 24px;
    }
  }
  .emblaPriceList .embla__slide img {
    width: 100%;
    height: 100%;
  }
  .grecaptcha-badge {
    display: none !important;
  }
}

.flatpickr-input {
  display: none;
}
body .flatpickr-calendar,
body .flatpickr-months .flatpickr-month,
body .flatpickr-current-month .flatpickr-monthDropdown-months,
body span.flatpickr-weekday {
  background: rgb(240 177 13);
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

body .flatpickr-calendar * {
  color: black;
  font-weight: 600;
}

body span.flatpickr-weekday {
  color: black;
  font-weight: 600;
}

body .flatpickr-months .flatpickr-prev-month svg path,
body .flatpickr-months .flatpickr-next-month svg path {
  fill: black;
}

body .flatpickr-day.inRange,
body .flatpickr-day.prevMonthDay.inRange,
body .flatpickr-day.nextMonthDay.inRange,
body .flatpickr-day.today.inRange,
body .flatpickr-day.prevMonthDay.today.inRange,
body .flatpickr-day.nextMonthDay.today.inRange,
body .flatpickr-day:hover,
body .flatpickr-day.prevMonthDay:hover,
body .flatpickr-day.nextMonthDay:hover,
body .flatpickr-day:focus,
body .flatpickr-day.prevMonthDay:focus,
body .flatpickr-day.nextMonthDay:focus {
  background: black;
  border-color: black;
  color: white;
}

body .flatpickr-day.selected,
body .flatpickr-day.startRange,
body .flatpickr-day.endRange,
body .flatpickr-day.selected.inRange,
body .flatpickr-day.startRange.inRange,
body .flatpickr-day.endRange.inRange,
body .flatpickr-day.selected:focus,
body .flatpickr-day.startRange:focus,
body .flatpickr-day.endRange:focus,
body .flatpickr-day.selected:hover,
body .flatpickr-day.startRange:hover,
body .flatpickr-day.endRange:hover,
body .flatpickr-day.selected.prevMonthDay,
body .flatpickr-day.startRange.prevMonthDay,
body .flatpickr-day.endRange.prevMonthDay,
body .flatpickr-day.selected.nextMonthDay,
body .flatpickr-day.startRange.nextMonthDay,
body .flatpickr-day.endRange.nextMonthDay {
  background: white;
  color: black;
  border-color: white;
}

.flatpickr-calendar.inline {
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

body .flatpickr-days {
  width: 100%;
}

body .flatpickr-day {
  max-width: unset;
  margin-bottom: 8px;
}

.flatpickr-calendar .dayContainer {
  width: 100%;
  max-width: 100%;
  padding: 0 8px;
}

body .flatpickr-rContainer {
  width: 100%;
}

.homepageHeroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(19, 19, 19, 0) 78.19%, rgba(19, 19, 19, 0.99) 100%);
  z-index: 1;
}

.priceListImgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(19, 19, 19, 0) 56.25%, rgba(19, 19, 19, 0.8) 98.47%);
  z-index: 1;
}

.arrivalWrapper,
.departureWrapper {
  position: relative;
}
.arrivalWrapper::before,
.departureWrapper::before {
  content: '';
  background-image: url('../public/img/Calendar.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
}

body .flatpickr-monthDropdown-months,
body .flatpickr-current-month .flatpickr-monthDropdown-months,
body .numInputWrapper .numInput,
body .flatpickr-current-month input.cur-year {
  font-size: 16px;
}

@media all and (max-width: 1024px) {
  .embla-homepage .embla__button--next,
  .embla-homepage .embla__button--prev {
    display: none;
  }
}

body .flatpickr-calendar:before,
body .flatpickr-calendar:after {
  display: none;
}

.grecaptcha-badge {
  display: none !important;
}

#arrivalTime,
#departureTime {
  -webkit-appearance: none;
  -moz-appearance: none;
}
